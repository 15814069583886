import React, { useState } from "react";
import { Drawer, IconButton, List, ListItemText } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Header.css"; // Import the updated CSS

const Header = ({ logo, email, onSignOut }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <header className="header">
      <div className="header-center">
        <img src={logo} alt="Scholarship Clipper Logo" className="header-logo" />
      </div>
      <div className="header-right">
        <IconButton onClick={handleToggleDrawer} className="header-icon">
          <AccountCircleIcon /> {/* Ensure this is correctly styled */}
        </IconButton>
      </div>
      <Drawer anchor="right" open={drawerOpen} onClose={handleToggleDrawer}>
        <List style={{ width: "250px", padding: "16px" }}>
          <ListItemText primary="Logged in as:" secondary={email} />
          <li className="drawer-list-item">
            <button className="drawer-button" onClick={onSignOut}>
              <LogoutIcon className="drawer-button-icon" />
              <span>Sign Out</span>
            </button>
          </li>
        </List>
      </Drawer>
    </header>
  );
};

export default Header;
