import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getScholarships = async (userId, token) => {
  const response = await apiClient.get(`/scholarships?userId=${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.map((item, index) => ({
    ...item,
    id: item.id,
    count: index + 1,
    deadline: item.deadline ? item.deadline.split("T")[0] : null,
  }));
};

export const deleteScholarship = async (id, token) => {
  await apiClient.delete(`/scholarships/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateScholarship = async (id, updatedData, token) => {
  await apiClient.put(`/scholarships/${id}`, updatedData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
