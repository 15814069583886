import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getScholarships, deleteScholarship, updateScholarship } from '../services/scholarships';
import { useAuth } from "react-oidc-context";
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress from '@mui/material/LinearProgress';

import './scholarshipTable.css';

const booleanCellRenderer = (value) => (
  <span style={{ color: value ? 'green' : 'red', fontWeight: 'bold' }}>
    {value ? '✔' : '✘'}
  </span>
);

const ScholarshipTable = () => {
  const [userId, setUserId] = useState(1); // Default to David's userId
  const auth = useAuth();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const goalAmount = 30000;

  const totalWon = useMemo(
    () => rows.reduce((sum, row) => (row.won ? sum + (parseFloat(row.amount) || 0) : sum), 0),
    [rows]
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (auth.user) {
          const token = auth.user.access_token; // Get the access token
          const data = await getScholarships(userId, token); // Pass token to the request
          setRows(data);
        }
      } catch (error) {
        console.error("Error fetching scholarships:", error);
        alert("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.user, userId]);

  const handleDelete = useCallback(
    async (id) => {
      const confirmation = window.confirm(
        "Are you sure you want to delete this scholarship? This action cannot be undone."
      );
      if (!confirmation) return;

      try {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));

        if (auth.user) {
          const token = auth.user.access_token; // Get the access token
          await deleteScholarship(id, token); // Pass token to the request
        }
      } catch (error) {
        console.error("Error deleting row:", error);
        alert("Failed to delete row.");
      }
    },
    [auth.user]
  );

  const handleProcessRowUpdate = useCallback(
    async (updatedRow) => {
      const originalRow = rows.find((row) => row.id === updatedRow.id);
      if (!originalRow) {
        console.error(`Original row not found for ID: ${updatedRow.id}`);
        alert("Failed to find original row.");
        return updatedRow;
      }

      const changedFields = Object.fromEntries(
        Object.entries(updatedRow).filter(([key, value]) => key !== "count" && value !== originalRow[key])
      );

      if (!Object.keys(changedFields).length) return updatedRow;

      try {
        if (auth.user) {
          const token = auth.user.access_token; // Get the access token
          await updateScholarship(updatedRow.id, changedFields, token); // Pass token to the request
        }
        setRows((prevRows) =>
          prevRows.map((row) => (row.id === updatedRow.id ? { ...row, ...changedFields } : row))
        );
        return { ...updatedRow, ...changedFields };
      } catch (error) {
        console.error("Error updating row:", error);
        alert("Failed to save changes.");
        return originalRow;
      }
    },
    [auth.user, rows]
  );

  const columns = useMemo(() => [
    { field: 'count', headerName: '#', width: 30, editable: false },
    {
      field: 'name',
      headerName: 'Scholarship',
      width: 250,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.name || ''} arrow>
          <a href={params.row.url} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        </Tooltip>
      ),
    },
    { field: 'amount', headerName: 'Amount', width: 80, editable: true, renderCell: (params) => `$${params.value}` },
    { field: 'deadline', headerName: 'Deadline', width: 120, editable: true },
    {
      field: 'submitted',
      headerName: 'Submitted',
      width: 100,
      editable: true,
      type: 'boolean', // Enables checkbox in edit mode
      renderCell: ({ value }) => booleanCellRenderer(value),
    },
    {
      field: 'won',
      headerName: 'Won',
      width: 80,
      editable: true,
      type: 'boolean', // Enables checkbox in edit mode
      renderCell: ({ value }) => booleanCellRenderer(value),
    },
    {
      field: 'renewable',
      headerName: 'Renewable',
      width: 100,
      editable: true,
      type: 'boolean', // Enables checkbox in edit mode
      renderCell: ({ value }) => booleanCellRenderer(value),
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 370,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.notes || ''} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      headerName: 'Trash',
      width: 70,
      renderCell: (params) => (
        <DeleteIcon
          onClick={() => handleDelete(params.row.id)}
          style={{ color: '#888', cursor: 'pointer' }}
        />
      ),
    },
  ], [handleDelete]);
  

  return (
    <div className="sortable-table-container" style={{ backgroundColor: '#f5f5f5', padding: '16px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Tabs
          value={userId}
          onChange={(e, newValue) => setUserId(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
          sx={{ '.MuiTab-root': { fontSize: '1.2rem', fontWeight: 'bold' } }}
        >
          {['David', 'Jonathan', 'Jacob'].map((name, index) => (
            <Tab key={name} label={name} value={index + 1} />
          ))}
        </Tabs>
        <div style={{ width: '300px', textAlign: 'right' }}>
          <p style={{ margin: 0, fontSize: '16px', fontWeight: 'bold', color: '#333' }}>
            Total Won: <strong>${totalWon.toLocaleString()}</strong> / ${goalAmount.toLocaleString()}
          </p>
          <LinearProgress
            variant="determinate"
            value={(totalWon / goalAmount) * 100}
            sx={{
              height: '8px',
              borderRadius: '4px',
              marginTop: '8px',
              backgroundColor: '#e0e0e0',
              '& .MuiLinearProgress-bar': { backgroundColor: '#147D4A' },
            }}
          />
        </div>
      </div>
      <div className="sortable-table-wrapper" style={{ height: 'calc(100vh - 200px)', overflow: 'auto', width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          pagination
          loading={loading}
          disableSelectionOnClick
          processRowUpdate={handleProcessRowUpdate}
          sortingOrder={['asc', 'desc']}
          components={{
            NoRowsOverlay: () => <div style={{ padding: '20px', textAlign: 'center', color: '#888' }}>No scholarships available.</div>,
            LoadingOverlay: LinearProgress,
          }}
        />
      </div>
    </div>
    
  );
};

export default ScholarshipTable;