import React from "react";
import { Button } from "@mui/material";

const SignInButton = ({ onSignIn }) => (
  <Button
    variant="contained"
    onClick={onSignIn}
    sx={{
      fontWeight: "bold",
      fontSize: "1rem",
      padding: "8px 16px",
      borderRadius: "8px",
      textTransform: "none",
      backgroundColor: "#147D4A", // Set the button's background color
      color: "#ffffff", // Ensure the text color is readable
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      "&:hover": {
        backgroundColor: "#1D8B55", // A slightly lighter shade for hover
      },
    }}
  >
    Sign In
  </Button>
);

export default SignInButton;
